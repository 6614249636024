import * as React from 'react';
import { TextField, Button, FormControl, Grid, Paper, ThemeProvider, ButtonGroup } from '@material-ui/core';
import { getModalStyle, getModalClasses } from '../../hooks/styles';
import { CabinetActions, ProductActions } from '../../redux/actions';
import { CabinetStyles } from './cabinet.styles';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { SelectBox } from '../../components/selectBox';
import { uniqBy } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { getCustomerProductList } from '../../hooks/functions';

const CabinetProductInventory: React.FC<any> = props => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();
	const [productList] = React.useState(
		uniqBy(
			props.inventory.map((product: any) => {
				return { label: `${product.productLabelName} (${product.productItemId})`, value: product.productItemId };
			}),
			'label',
		),
	);

	const [selectedProduct, setSelectedProduct] = React.useState(null as any);
	const [productState, setProductState] = React.useState(productList.length === 0 ? 'new' : 'existing');
	const [lotState, setLotState] = React.useState<string>(productList.length === 0 ? 'new' : 'existing');
	const [input, setInput] = React.useState<any>({ reason: 'VEND' });
	const [inputErrors, setInputErrors] = React.useState<any>({});
	const [suspended, setSuspended] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (productList.length === 0) {
			handleProductAutoComplete(null);
		}
	}, [productList]);

	const handleProductAutoComplete = (values?: any) => {
		const filter: any = {
			fields: {
				productId: true,
				productName: true,
			},
			offset: 0,
			limit: 100,
			skip: 0,
			order: ['productId'],
		};

		if (values) {
			filter.where = {
				or: [
					{ productName: { like: '%' + values + '%' } },
					{ productId: { like: '%' + values + '%' } },
					{ mbrxProductId: { like: '%' + values + '%' } },
				],
			};
		}
		props.getAutoCompleteProducts(filter);
	};

	const getLotList = () => {
		if (!input.productItemId) {
			return null;
		}
		return props.inventory
			.filter((product: any) => product.productItemId === input.productItemId)
			.map((product: any) => ({ label: product.lot, value: product.lot }));
	};

	const getProviderList = () => {
		if (props.cabinet.cabinetProviders) {
			return props.cabinet.cabinetProviders.map((provider: any) => ({
				label: ` ${provider.npi} - ${provider.firstName} ${provider.lastName}`,
				value: provider.npi,
			}));
		}
		return [];
	};
	const getLotExpiration = (lot: string) => {
		if (!lot) {
			return '';
		}
		return props.inventory.filter((product: any) => {
			return product.lot === lot;
		})[0].expiration;
	};

	const handleProductStateChange = (state: string) => {
		setProductState(state);
		setLotState(state);
		setInput({ ...input, lot: '' });
		if (state === 'new') {
			handleProductAutoComplete(null);
		}
	};

	const handleLotStateChange = (state: string) => {
		setLotState(state);
		setInput({ ...input, lot: '' });
	};

	const getMaxFill = () => {
		if (['Virtual', 'SITE', 'ZONE'].includes(props.cabinet.cabinetType)) {
			return 999;
		}
		if (['EM', 'MinibarRx'].includes(props.cabinet.cabinetType)) {
			return 1;
		}
		if (['RFID', 'VIPc'].includes(props.cabinet.cabinetType)) {
			return 20;
		}
	};

	const expirationIsValid = () => {
		if (props.action === 'remove') {
			return true;
		}

		if (!input.expiration) {
			setInputErrors({ ...inputErrors, expiration: 'expiration is required.' });
			return false;
		}

		if (moment().diff(moment(input.expiration, 'YYYY-MM-DD'), 'day') > 0) {
			setInputErrors({ ...inputErrors, expiration: 'Cannot insert an expired product.' });
			return false;
		}

		return true;
	};

	const qtyIsValid = () => {
		if (parseInt(input.qty) < 1) {
			setInputErrors({ ...inputErrors, qty: 'Quantity cannot be less than 1.' });
			return false;
		}

		if (!input.qty) {
			setInputErrors({ ...inputErrors, qty: 'Quantity is required.' });
			return false;
		}

		if (props.action === 'insert') {
			return true;
		}

		const onHand = props.inventory.filter(
			(product: any) => product.productItemId === input.productItemId && product.lot === input.lot,
		)[0].salesUoUQty;

		if (parseInt(input.qty) > onHand) {
			setInputErrors({ ...inputErrors, qty: `Cannot remove more than current on hand amount (${onHand}).` });
			return false;
		}

		return true;
	};

	const providerIsValid = () => {
		if (
			props.cabinet.cabinetProperties.providerTracking &&
			props.cabinet.cabinetProperties.providerTracking === 'Yes' &&
			!input.provider
		) {
			setInputErrors({ ...inputErrors, provider: 'Provider is required.' });
			return false;
		}

		return true;
	};

	const submit = () => {
		if (!input.productItemId || !input.lot || !expirationIsValid() || !qtyIsValid() || !providerIsValid()) {
			return;
		}
		const inventoryPayload = {
			action: props.action === 'remove' ? 'removal' : props.action,
			callback: (isSuspended: boolean = false) => {
				if (isSuspended) {
					setSuspended(isSuspended);
					return;
				}
				props.closeModal(true);
			},
			data: {
				customerId: props.cabinet.customerId,
				cabinetId: props.cabinet.cabinetId,
				productItemId: input.productItemId,
				reason: input.reason || 'VEND',
				lot: input.lot,
				...(props.action === 'insert' && { expiration: input.expiration }),
				qty: parseInt(input.qty),
				...(input.provider && { npiProviderId: input.provider }),
				...(props.action == 'remove' && { customerNote: input?.customerNote }),
			},
		};

		if (productState === 'new') {
			const productPayload = {
				productItemId: input.productItemId,
				cabinetId: props.cabinet.cabinetId,
				maxUoUFillQty: getMaxFill(),
				customerId: props.cabinet.customerId,
				callback: () => {
					props.updateCabinetProductInventory(inventoryPayload);
				},
			};
			props.addProduct(productPayload);
			return;
		}

		props.updateCabinetProductInventory(inventoryPayload);
	};

	return (
		<div
			style={{ ...getModalStyle(), padding: '20px', width: isDesktopOrLaptop ? 'auto' : '90%' }}
			className={modalClasses.paper}
		>
			{!suspended && (
				<Grid container>
					<Grid item xs={12}>
						<Paper variant="outlined" style={{ width: '100%', padding: 15 }}>
							<div className={classes.inventoryActionHeading}>
								{props.action === 'insert' ? 'Insert' : 'Remove'} Inventory
							</div>
							{productList.length > 0 && props.action === 'insert' && (
								<div>
									<ButtonGroup size="small" aria-label="small outlined button group" style={{ margin: '5px 0 8px 0' }}>
										<Button
											style={{ fontSize: '10px' }}
											onClick={() => handleProductStateChange('existing')}
											className={productState === 'existing' ? classes.selectedLotState : ''}
										>
											Existing
										</Button>
										<Button
											style={{ fontSize: '10px' }}
											onClick={() => handleProductStateChange('new')}
											className={productState === 'new' ? classes.selectedLotState : ''}
										>
											New
										</Button>
									</ButtonGroup>
								</div>
							)}
							<div>
								{productState === 'existing' && (
									<FormControl required variant="outlined" className={classes.formControl}>
										<SelectBox
											style={{ width: 400 }}
											inputLabel={'Product'}
											emptyItemLabel={'Select Product'}
											listItems={productList}
											error={inputErrors.productItemId ? true : false}
											errorText={inputErrors.productItemId || ''}
											onChangeItem={(value: any) => {
												setInput({ ...input, productItemId: value, lot: null, expiration: null });
											}}
										/>
									</FormControl>
								)}
								{productState === 'new' && (
									<FormControl required variant="outlined" className={classes.formControl}>
										<Autocomplete
											id="product-combo-box"
											options={getCustomerProductList(props.allProductsList, props.cabinet.customerId)}
											getOptionLabel={(option: any) => '(' + option.productItemId + ') ' + option.productLabelName}
											style={{ width: 210 }}
											onChange={(event: any, value: any) => {
												setSelectedProduct(value || null);
												setInput({ ...input, productItemId: value?.productItemId || '' });
											}}
											value={selectedProduct || null}
											renderInput={(params: any) => (
												<TextField
													{...params}
													label={'Search Products'}
													error={inputErrors.productItemId ? true : false}
													helperText={inputErrors.productItemId || ''}
													required
												/>
											)}
										/>
									</FormControl>
								)}
							</div>
							{props.action === 'insert' && productState === 'existing' && (
								<div>
									<ButtonGroup size="small" aria-label="small outlined button group" style={{ margin: '20px 0 8px 0' }}>
										<Button
											style={{ fontSize: '10px' }}
											onClick={() => handleLotStateChange('existing')}
											className={lotState === 'existing' ? classes.selectedLotState : ''}
										>
											Existing
										</Button>
										<Button
											style={{ fontSize: '10px' }}
											onClick={() => handleLotStateChange('new')}
											className={lotState === 'new' ? classes.selectedLotState : ''}
										>
											New
										</Button>
									</ButtonGroup>
								</div>
							)}
							<div>
								{lotState === 'existing' && (
									<FormControl required variant="outlined" className={classes.formControl}>
										<SelectBox
											style={{ width: 210 }}
											inputLabel={'Lot'}
											emptyItemLabel={'Select Lot'}
											disabled={input.productItemId ? false : true}
											listItems={getLotList() || undefined}
											error={inputErrors.lot ? true : false}
											errorText={inputErrors.lot || ''}
											onChangeItem={(value: any) => {
												setInput({ ...input, lot: value, expiration: getLotExpiration(value) });
											}}
										/>
									</FormControl>
								)}
								{lotState === 'new' && (
									<FormControl required variant="outlined" className={classes.formControl}>
										<TextField
											required
											style={{ width: 210 }}
											type="text"
											label="Lot"
											placeholder="Enter Lot"
											variant="outlined"
											error={inputErrors.lot ? true : false}
											helperText={inputErrors.lot || ''}
											value={input.lot || ''}
											onChange={e => setInput({ ...input, lot: e.target.value })}
										/>
									</FormControl>
								)}
							</div>
							{lotState === 'new' && (
								<div>
									<FormControl required className={classes.formControl}>
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												autoOk
												disablePast
												format="YYYY-MM-DD"
												variant="inline"
												label="Expiration"
												disabled={input.lot ? false : true}
												InputAdornmentProps={{ position: 'end' }}
												error={inputErrors.expiration ? true : false}
												helperText={inputErrors.expiration || ''}
												value={input.expiration || null}
												emptyLabel="YYYY-MM-DD"
												onChange={(date: any) => {
													setInput({ ...input, expiration: date?.isValid() ? date.format('YYYY-MM-DD') : '' });
													setInputErrors({ ...inputErrors, expiration: null });
												}}
											/>
										</MuiPickersUtilsProvider>
									</FormControl>
								</div>
							)}
							<div>
								<FormControl required variant="outlined" className={classes.formControl}>
									<TextField
										required
										type="number"
										InputProps={{
											inputProps: {
												min: 1,
												step: '1',
												onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57,
											},
										}}
										label="Quantity"
										variant="outlined"
										style={{ width: '90px' }}
										disabled={input.expiration ? false : true}
										error={inputErrors.qty ? true : false}
										helperText={inputErrors.qty || ''}
										value={input.qty || ''}
										FormHelperTextProps={{ style: { width: '210px' } }}
										onChange={e => {
											setInput({ ...input, qty: e.target.value });
											setInputErrors({ ...inputErrors, qty: null });
										}}
									/>
								</FormControl>
								{props.action == 'remove' && (
									<div>
										<FormControl required variant="outlined" className={classes.formControl}>
											<TextField
												style={{ width: 400 }}
												type="text"
												label="Memo"
												placeholder="Memo (optional)"
												variant="outlined"
												error={inputErrors.customerNote ? true : false}
												helperText={inputErrors.customerNote || ''}
												value={input.customerNote || ''}
												onChange={e => setInput({ ...input, customerNote: e.target.value })}
											/>
										</FormControl>
									</div>
								)}
								{props.action == 'remove' &&
									props.cabinet.cabinetProperties.providerTracking &&
									props.cabinet.cabinetProperties.providerTracking !== 'No' && (
										<div>
											<FormControl required variant="outlined" className={classes.formControl}>
												<SelectBox
													required={props.cabinet.cabinetProperties.providerTracking === 'Yes'}
													style={{ width: 210 }}
													inputLabel={'Provider'}
													emptyItemLabel={'Select Provider'}
													listItems={getProviderList() || undefined}
													error={inputErrors.provider ? true : false}
													errorText={inputErrors.provider || ''}
													onChangeItem={(value: any) => {
														setInput({ ...input, provider: value });
														setInputErrors({ ...inputErrors, provider: null });
													}}
												/>
											</FormControl>
										</div>
									)}
								{props.action == 'remove' &&
									props.cabinet.cabinetProperties.consignment &&
									props.cabinet.cabinetProperties.consignment === '1' && (
										<FormControl required variant="outlined" className={classes.formControl}>
											<SelectBox
												style={{ width: 210 }}
												inputLabel={'Reason'}
												selected={input.reason}
												hideEmptyLabel={true}
												listItems={[
													{ label: 'Vend', value: 'VEND' },
													{ label: 'Return', value: 'RETURN' },
												]}
												onChangeItem={(value: any) => {
													setInput({ ...input, reason: value });
												}}
											/>
										</FormControl>
									)}
							</div>
							{props.error && <p className={classes.cabinetFormError}>Failed to {props.action} inventory.</p>}
							<div style={{ textAlign: 'center', marginTop: 20 }}>
								<FormControl
									className={[classes.formControl, !isDesktopOrLaptop ? classes.formControlMobile : ''].join(' ')}
								>
									<Button
										className={classes.viewAllBtn}
										style={{ margin: 0 }}
										variant="contained"
										color="secondary"
										onClick={() => {
											submit();
										}}
									>
										{props.action.toUpperCase()}
									</Button>
								</FormControl>
								<FormControl className={classes.formControl}>
									<Button
										className={classes.cancelBtn}
										variant="contained"
										color="default"
										style={{ marginTop: 0 }}
										onClick={() => {
											props.closeModal();
										}}
									>
										CANCEL
									</Button>
								</FormControl>
							</div>
						</Paper>
					</Grid>
				</Grid>
			)}
			{suspended && (
				<div style={{ textAlign: 'center' }}>
					<b>This transaction is in review.</b>
					<br />
					Current on hand inventory will not reflect this transaction until it is resolved.
					<Grid>
						<Grid item xs={12} style={{ textAlign: 'center', marginTop: 20 }}>
							<FormControl className={classes.formControl}>
								<Button
									className={classes.cancelBtn}
									variant="contained"
									color="default"
									style={{ marginTop: 0 }}
									onClick={() => {
										props.closeModal();
									}}
								>
									CLOSE
								</Button>
							</FormControl>
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinetProductInventory: state.cabinet.cabinetProductInventory,
	autoCompleteProducts: state.product.autoCompleteList || [],
	allProductsList: state.product.allProductsList || [],
	error: state.cabinet.error,
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetProductInventory: (payload: any) => dispatch(CabinetActions.getCabinetProductInventory(payload)),
	getAutoCompleteProducts: (filter: any) => dispatch(ProductActions.getProductAutoCompleteList(filter)),
	addProduct: (payload: any) => dispatch(CabinetActions.addCabinetProduct(payload)),
	updateCabinetProductInventory: (payload: any) => dispatch(CabinetActions.updateCabinetProductInventory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetProductInventory);
