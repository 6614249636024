import React from "react";
import { getModalClasses, getModalStyle } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from 'highcharts';
import { valueMapper } from "../../../hooks/functions";

const DashboardBarChartRendering: React.FC<any> = props => {

    console.log(`DashboardBarChartRendering called`)
    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());

    const handleClose = () => {
        props.closeChart();
    };

    const options = {
        title: {
            text: props.settings.displayName
        },
        chart: {
            type: "column"
        },
        tooltip: {
            format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
                'Total: {point.stackTotal}'
        },
        xAxis: {
            categories: props.records.categories
        },

        yAxis: {
            stackLabels: {
                enabled: true
            },
            allowDecimals: false,
            min: 0,
            title: {
                text: ''
            }
        },
        plotOptions: {
            column: {
                animation: props?.animation ?? true,
                stacking: 'normal'
            }
        },
        series: props.records.chartData
            .map((el: { [key: string]: any }) => {
                if (el.name && ['EM', 'RFID', 'WEIGHT', 'VISION', 'Virtual', 'SITE', 'ZONE'].includes(el.name)) {
                    el.name = valueMapper(el.name, 'cabinetTypes')
                }
                return el;
            }
            )
    };

    return (
        <div style={{ ...modalStyle, maxWidth: 1200 }} className={modalClasses.paper}>
            <div className={modalClasses.closeBtn} onClick={handleClose}>
                <CancelIcon color="disabled"></CancelIcon>
            </div>
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBarChartRendering);