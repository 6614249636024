import React from 'react';
import { connect } from 'react-redux';
import { CabinetActions, ProductActions, TenantsActions } from '../../../redux/actions';
import {
	makeStyles,
	createStyles,
	Theme,
	Button,
	Table,
	TableBody,
	TableRow,
	TableCell,
	FormControl,
	TextField,
	FormControlLabel,
	Switch,
	TextareaAutosize,
} from '@material-ui/core';
import { CabinetStyles } from '../../cabinets/cabinet.styles';
import { globalColors } from '../../../hooks/styles/muiTheme';
import { SelectBox } from '../../../components/selectBox';
import { getCustomerProductList } from '../../../hooks/functions';
import { Autocomplete } from '@material-ui/lab';
import { Cabinet } from '../../../components/autoComplete';

export const fffSources = ['FFF', 'LUM', 'MBRX'];

function getSetParModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useSetParStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalActionError: {
			color: '#f44336',
			fontWeight: 'bold',
		},
		poTypeError: {
			color: '#f44336',
		},
		confirmBtn: {
			'background-color': `${globalColors.FFF_BLUE} !important`,
			color: '#fff !important',
		},
		poTypeModalTable: {
			marginBottom: 0,
			border: 'none',
		},
		poTypeModalPaper: {
			borderRadius: '3px',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			position: 'absolute',
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
		},
	}),
);

interface Props {
	addProduct?: boolean;
	allProductsList: any[];
	customerId: any;
	product: any;
	error: boolean;
	isLoading: boolean;
	productSource: string;
	closeModal: (reset?: boolean) => void;
	updatePar: (payload: any) => void;
	newProductSubmitted: boolean;
	setParPayload: (payload: any) => void;
	cabinetList: any;
	submit: boolean;
}

const SetParModal: React.FC<Props> = props => {
	const poModalClasses = useSetParStyles();
	const classes = CabinetStyles();
	const [modalStyle] = React.useState(getSetParModalStyle);
	const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
	const [isSelectedProductMbrx, setIsSelectedProductMbrx] = React.useState<boolean>(false);
	const [selectedProductError, setSelectedProductError] = React.useState<any>(null);
	const [poType, setPoType] = React.useState<any>(props.product?.poType || 'FCP');
	const [poTypeError, setPoTypeError] = React.useState<any>(null);
	const [salesType, setSalesType] = React.useState<any>(props.product?.salesType || 'FCP');
	const [salesTypeError, setSalesTypeError] = React.useState<any>(null);
	const [minSet, setMinSet] = React.useState<any>(props.product?.minSet || 0);
	const [maxSet, setMaxSet] = React.useState<any>(props.product?.maxSet || 0);
	const [maxFill, setMaxFill] = React.useState<any>(props.product?.maxFill || 0);
	const [crl, setCrl] = React.useState<any>(props.product?.crl || 0);
	const [active, setActive] = React.useState(props.product?.active ? true : false);
	const [packToLum, setPackToLum] = React.useState(props.product?.packToLum ? true : false);
	const [notes, setNotes] = React.useState(props.product?.notes || '');
	const [submitted, setSubmitted] = React.useState(false as boolean);
	const [selectedCabinetId, setSelectedCabinetId] = React.useState<any>('');
	const [selectedCabinetType, setSelectedCabinetType] = React.useState<string>(props.product?.cabinetType || '');
	const [selectedSapProductId, setSelectedSapProductId] = React.useState<string>(props.product?.defaultSapProduct || '');
	const [cabinetTypeError, setCabinetTypeError] = React.useState<any>(null);
	const [productList, setProductList] = React.useState<any[]>(
		props.allProductsList.filter((product: any) => !fffSources.includes(product.source)),
	);

	React.useEffect(() => {
		setSelectedCabinetId('');
	}, [selectedCabinetType]);

	React.useEffect(() => {
		if (props.isLoading) {
			setSubmitted(true);
		}
		if (submitted && !props.isLoading && !props.error) {
			props.closeModal(true);
		}
	}, [props, submitted]);

	React.useEffect(() => {
		let cabinetTypeProducts: any[] = [];

		cabinetTypeProducts = getCustomerProductList(props.allProductsList, props.customerId).filter(product => {
			if (!fffSources.includes(product.source)) {
				return false;
			}

			if (selectedCabinetType === 'EM') {
				return product.isMBRXProduct ? true : false;
			} else {
				return product.isMBRXProduct ? false : true;
			}
		});

		setProductList(cabinetTypeProducts);
	}, [selectedCabinetType]);

	const handleModalCancel = () => {
		setSubmitted(false);
		props.closeModal();
	};

	const changePoType = (value: any) => {
		if (value) {
			setPoTypeError(null);
		}
		setPoType(value);
	};

	const changeSalesType = (value: any) => {
		if (value) {
			setSalesTypeError(null);
		}
		setSalesType(value);
	};

	const changeInput = (name: string, value: any) => {
		let setFn: any = (val: any) => {};
		if (name === 'crl') setFn = setCrl;
		if (name === 'maxFill') setFn = setMaxFill;
		if (name === 'minSet') setFn = setMinSet;
		if (name === 'maxSet') setFn = setMaxSet;
		if (name === 'active') setFn = setActive;
		if (name === 'packToLum') setFn = setPackToLum;
		if (name === 'notes') setFn = setNotes;
		setFn(value);
	};

	const handleParUpdateSubmit = () => {
		let submitErrors: boolean = false;

		if (props.addProduct && fffSources.includes(props.productSource) && !selectedProduct) {
			setSelectedProductError('A Product is required.');
			props.setParPayload(null);
			submitErrors = true;
		}

		if (!selectedCabinetType) {
			setCabinetTypeError('A Cabinet Type is required.');
			props.setParPayload(null);
			submitErrors = true;
		}

		if (submitErrors) {
			return;
		}

		setSubmitted(false);

		const payload: any = {
			customerId: props.customerId,
			cabinetType: selectedCabinetType,
			poType:  fffSources.includes(props.productSource) ? poType : '3PS',
			salesType: fffSources.includes(props.productSource) ? salesType : '3PS',
			crl: +crl || undefined,
			maxFill: +maxFill || undefined,
			minSet: +minSet || undefined,
			maxSet: +maxSet || undefined,
			active: active ? 1 : 0,
			packToLum: packToLum ? 1 : 0,
			notes: notes || undefined
		};

		if (selectedCabinetId) {
			payload.cabinetId = selectedCabinetId;
		}
		
		if (props.addProduct) {
			if (fffSources.includes(props.productSource)) {
				payload.productId = selectedProduct.productItemId;
				if (selectedProduct.sapProductId) payload.defaultSapProduct = selectedProduct.sapProductId;
			}
			props.setParPayload(payload);
		} else {
			payload.productId = props.product.productId;
			payload.customerProductId = props.product.id;
			if (props.productSource !== 'FFF') payload.defaultSapProduct = props.product.sapProducts && props.product.sapProducts.length > 1 ? selectedSapProductId : props.product.sapProducts[0].productId;
			props.updatePar(payload);
		}
	};

	const getProductName = (product: any) => {
		if (product.productName) {
			return product.productName;
		}
		if (product.product?.productName) {
			return product.product.productName;
		}
		return '';
	}

	React.useEffect(() => {
		props.submit && handleParUpdateSubmit();
	}, [props.submit]);

	React.useEffect(() => {
		if (!fffSources.includes(props.productSource)) {
			setSelectedProduct(null);
		}
	}, [props.productSource]);

	return (
		<div>
			{!props.addProduct && (
				<h4>
					Product: {getProductName(props.product)} ({props.product.productId})
				</h4>
			)}
			<Table className={poModalClasses.poTypeModalTable}>
				<TableBody>
					<TableRow>
						<TableCell style={{ border: 'none' }} colSpan={4}>
							<FormControl className={classes.formControl}>
								<SelectBox
									refresh={false}
									selected={selectedCabinetType}
									style={{ width: 250 }}
									inputLabel="Select Device Type"
									emptyItemLabel={'Choose'}
									listItems={[
										{ label: 'RightNow Ready', value: 'EM' },
										{ label: 'RightNow Verified', value: 'RFID' },
										{ label: 'RightNow Cloud', value: 'Virtual' },
										{ label: 'RightNow Flex', value: 'WEIGHT' },
										{ label: 'RightNow Secure', value: 'VISION' },
										{ label: 'Anywhere Site', value: 'SITE' },
										{ label: 'Anywhere Zone', value: 'ZONE' },
									]}
									onChangeItem={val => {
										setSelectedCabinetType(val);
									}}
									required
								/>
								<div className={poModalClasses.poTypeError}>{cabinetTypeError}</div>
							</FormControl>
						</TableCell>
					</TableRow>
					{props.product && props.product.sapProducts && props.product.sapProducts.length > 1 && 
						<TableRow>
							<TableCell style={{ border: 'none' }} colSpan={4}>
								<FormControl className={classes.formControl}>
									<SelectBox
										refresh={false}
										selected={selectedSapProductId}
										style={{ width: 250 }}
										inputLabel="Default SAP Product"
										listItems={props.product.sapProducts.map((sapProduct: any) => ({
											label: `${sapProduct.productName} (${sapProduct.productId})`,
											value: sapProduct.productId,
										}))}
										onChangeItem={val => {
											setSelectedSapProductId(val);
										}}
										required
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					}
					{props.addProduct && fffSources.includes(props.productSource) && (
						<TableRow>
							<TableCell style={{ border: 'none' }} colSpan={4}>
								<FormControl required variant="outlined" className={classes.formControl}>
									<Autocomplete
										id="product-combo-box"
										options={productList}
										getOptionLabel={(option: any) =>
											'(' +
											option.productItemId +
											') ' +
											option.productLabelName +
											(option.isMBRXProduct ? ` (MBRX ext. ${option.mbrxExt}) ` : '')
										}
										style={{ width: 210 }}
										onChange={(event: any, value: any) => {
											setSelectedProduct(value || null);
											setIsSelectedProductMbrx(Boolean(value?.isMBRXProduct));
											setSelectedProductError(null);
										}}
										value={selectedProduct || null}
										renderInput={(params: any) => (
											<TextField
												{...params}
												label={'Search Products'}
												error={selectedProductError ? true : false}
												helperText={selectedProductError || ''}
												required
											/>
										)}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					)}
					{fffSources.includes(props.productSource) && (
						<TableRow>
							<TableCell style={{ border: 'none' }} colSpan={1}>
								<SelectBox
									refresh={false}
									selected={poType}
									style={{ width: 100 }}
									inputLabel="Select PO Type"
									listItems={[
										{ label: 'APO', value: 'APO' },
										{ label: 'BPO', value: 'BPO' },
										{ label: 'FCP', value: 'FCP' },
									]}
									onChangeItem={changePoType}
									required
									hideEmptyLabel
								/>
								<div className={poModalClasses.poTypeError}>{poTypeError}</div>
							</TableCell>
							<TableCell style={{ border: 'none' }} colSpan={1}>
								<SelectBox
									refresh={false}
									selected={salesType}
									style={{ width: 100 }}
									inputLabel="Select Sales Type"
									listItems={[
										{ label: 'FCP', value: 'FCP' },
										{ label: 'FBB', value: 'FBB' },
										{ label: '3PS', value: '3PS' },
									]}
									onChangeItem={changeSalesType}
									required
									hideEmptyLabel
								/>
								<div className={poModalClasses.poTypeError}>{salesTypeError}</div>
							</TableCell>
						</TableRow>
					)}
					<TableRow>
						<TableCell style={{ border: 'none' }} colSpan={1}>
							<TextField
								type="number"
								InputProps={{
									inputProps: { min: 1, step: '1', onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57 },
								}}
								label="Min Set"
								variant="outlined"
								style={{ width: '90px' }}
								value={minSet || ''}
								FormHelperTextProps={{ style: { width: '100px' } }}
								onChange={e => {
									changeInput('minSet', e.target.value);
								}}
							/>
						</TableCell>
						<TableCell style={{ border: 'none' }} colSpan={1}>
							<TextField
								type="number"
								InputProps={{
									inputProps: { min: 1, step: '1', onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57 },
								}}
								label="Max Set"
								variant="outlined"
								style={{ width: '90px' }}
								value={maxSet || ''}
								FormHelperTextProps={{ style: { width: '100px' } }}
								onChange={e => {
									changeInput('maxSet', e.target.value);
								}}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{ border: 'none' }} colSpan={1}>
							<TextField
								type="number"
								InputProps={{
									inputProps: { min: 1, step: '1', onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57 },
								}}
								label="CRL"
								variant="outlined"
								style={{ width: '90px' }}
								value={crl || ''}
								FormHelperTextProps={{ style: { width: '100px' } }}
								onChange={e => {
									changeInput('crl', e.target.value);
								}}
							/>
						</TableCell>
						<TableCell style={{ border: 'none' }} colSpan={1}>
							<TextField
								type="number"
								InputProps={{
									inputProps: { min: 1, step: '1', onKeyPress: e => parseInt(e.key) >= 48 && parseInt(e.key) <= 57 },
								}}
								label="Max Fill"
								variant="outlined"
								style={{ width: '90px' }}
								value={maxFill || ''}
								FormHelperTextProps={{ style: { width: '100px' } }}
								onChange={e => {
									changeInput('maxFill', e.target.value);
								}}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						{(fffSources.includes(props.productSource) || props.product) && (
							<TableCell style={{ border: 'none' }} colSpan={1}>
								<FormControl className={classes.formControl}>
									<FormControlLabel
										label={'Is Active'}
										control={
											<Switch
												name={'active'}
												onChange={() => {
													const currentValue = active ? true : false;
													changeInput('active', !currentValue);
												}}
												checked={active ? true : false}
											/>
										}
									/>
								</FormControl>
							</TableCell>
						)}
						{fffSources.includes(props.productSource) && (
							<TableCell style={{ border: 'none' }} colSpan={1}>
								<FormControl className={classes.formControl}>
									<FormControlLabel
										label={'Pack To Lum'}
										control={
											<Switch
												name={'packToLum'}
												onChange={() => {
													const currentValue = packToLum ? true : false;
													changeInput('packToLum', !currentValue);
												}}
												checked={packToLum ? true : false}
											/>
										}
									/>
								</FormControl>
							</TableCell>
						)}
					</TableRow>
					<TableRow>
					<TableCell style={{ border: 'none' }} colSpan={8}>
							<FormControl className={classes.formControl}>
								<p 
									className={classes.formControlLabel}
								>
									Product Notes
								</p>
								<TextareaAutosize
									minRows={3}
									maxRows={3}
									cols={40}
									placeholder="Enter product notes here"
									value={notes || ''}
									onChange={(e: any) => {
										changeInput('notes', e.target.value);
									}}
								/>
								</FormControl>
						</TableCell>
					</TableRow>
				</TableBody>
				{submitted && props.error && (
					<div className={poModalClasses.modalActionError}>Failed to update product settings.</div>
				)}
			</Table>
		</div>
	);
};

const mapStateToModalProps = (state: any) => ({
	allProductsList: state.product.allProductsList || [],
	error: state.ui.errorMessage,
	isLoading: state.ui.isLoading,
	selectedCustomer: state.ui.selectedCustomer,
	cabinetList: state.cabinet.cabinets ? state.cabinet.cabinets.result : [],
	newProductSubmitted: state.product.newProductSubmitted || false,
});

const mapDispatchToModalProps = (dispatch: any) => ({
	updatePar: (payload: any) => dispatch(CabinetActions.setCabinetProductPar(payload)),
});

export default connect(mapStateToModalProps, mapDispatchToModalProps)(SetParModal);
