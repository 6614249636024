import { Constants } from '../constants/Constants';
export default {
	Actions: [
		{ label: 'INSERT', value: 'INSERT' },
		{ label: 'REMOVAL', value: 'REMOVAL' },
		{ label: 'NO USAGE', value: 'NO_USAGE' },
		{ label: 'REVIEW REQD', value: 'REVIEW_REQD' },
		{ label: 'VEND', value: 'VEND' },
	],
	Reasons: [
		{ label: 'AUDIT', value: 'AUDIT' },
		{ label: 'COMPLETE', value: 'COMPLETE' },
		{ label: 'CONFIRMED VEND', value: 'CONFIRMED VEND' },
		{ label: 'EXPIRED', value: 'EXPIRED' },
		{ label: 'DEVICE TRANSFER RECON', value: 'DEVICE TRANSFER RECON' },
		{ label: 'INSERTED', value: 'INSERTED' },
		{ label: 'INTERNAL TRANSFER', value: 'INTERNAL TRANSFER' },
		{ label: 'INVENTORY SYNC', value: 'INVENTORY SYNC' },
		{ label: 'INVENTORY SYNC RECONCILIATION', value: 'INVENTORY SYNC RECONCILIATION' },
		{ label: 'OVERAGE', value: 'OVERAGE' },
		{ label: 'PENDING', value: 'PENDING' },
		{ label: 'PRE PAY', value: 'PRE PAY' },
		{ label: 'RE-INSERT', value: 'RE-INSERT' },
		{ label: 'RECEIPT', value: 'RECEIPT' },
		{ label: 'REFILL', value: 'REFILL' },
		{ label: 'REMOVED', value: 'REMOVED' },
		{ label: 'RETURN', value: 'RETURN' },
		{ label: 'REVIEW REQD', value: 'REVIEW_REQD' },
		{ label: 'SHORTAGE', value: 'SHORTAGE' },
		{ label: 'UNDO REFILL', value: 'UNDO REFILL' },
		{ label: 'UNDO VEND', value: 'UNDO VEND' },
		{ label: 'UNKNOWN', value: 'UNKNOWN' },
		{ label: 'VEND', value: 'VEND' },
		{ label: 'VEND PURCHASE', value: 'VEND PURCHASE' }
	],
	Invoiced: [
		{ label: 'INVOICED', value: 'INVOICED' },
		{ label: 'NOT INVOICED', value: 'NOT INVOICED' },
	],
	SetupStatus: [
		{ label: 'Complete', value: '1' },
		{ label: 'Incomplete', value: '0' },
	],
	CustomerSearchFilters: [
		//{ "label": Constants.SEARCH.CABINET_ID, "value": "CABINET_ID" },
		{ label: Constants.SEARCH.STATE, value: 'STATE' },
		{ label: Constants.SEARCH.ZIP_CODE, value: 'ZIP_CODE' },
		{ label: Constants.SEARCH.CREATED_DATE, value: 'CREATED_DATE' },
		//{"label":"Cancel Date","value":"CANCEL_DATE"}
	],

	UserSearchFilters: [
		{ label: Constants.SEARCH.FIRST_NAME, value: 'FIRST_NAME' },
		{ label: Constants.SEARCH.LAST_NAME, value: 'LAST_NAME' },
		{ label: Constants.SEARCH.EMAIL, value: 'EMAIL' },
	],

	IncidentSeverity: [
		{ label: 'High', value: 'high' },
		{ label: 'Low', value: 'low' },
	],

	IncidentStatus: [
		{ label: 'Open', value: 'Open' },
		{ label: 'Acknowledged', value: 'Acknowledged' },
		{ label: 'Closed', value: 'Closed' },
		{ label: 'Ignored', value: 'Ignored' },
	],

	InventorySearchFilters: [
		{ label: Constants.SEARCH.CABINET_ID, value: 'CABINET_ID' },
		{ label: Constants.SEARCH.PRODUCT_NAME, value: 'PRODUCT_NAME' },
		{ label: Constants.SEARCH.LOT, value: 'LOT' },
	],
	CabinetSearchFilters: [
		{ label: Constants.SEARCH.CABINET_TYPE, value: 'TYPE' },
		{ label: Constants.SEARCH.CABINET_STATE, value: 'STATE' },
	],

	//Cloud
	CabinetTypeDropdownList: [
		{ label: 'RightNow Ready', value: 'EM' },
		{ label: 'RightNow Verified', value: 'RFID' },
		{ label: 'RightNow Cloud', value: 'Virtual' },
		{ label: 'RightNow Flex', value: 'WEIGHT' },
		{ label: 'RightNow Secure', value: 'VISION' },
		{ label: 'Anywhere Site', value: 'SITE' },
		{ label: 'Anywhere Zone', value: 'ZONE' },
	],
	OrderTypeDropdownList: [
		{ label: 'ZMB', value: 'ZMB' },
		{ label: 'ZKB', value: 'ZKB' },
		{ label: 'ZKV', value: 'ZKV' },
	],
	InvoicePageFilters: [
		{ label: Constants.SEARCH.INVOICE_DATE, value: 'INVOICE_DATE' },
		//{ "label": Constants.SEARCH.PAYMENT_STATUS, "value": "PAYMENT_STATUS" },
	],
	HistoryPageFilters: [
		{ label: Constants.SEARCH.CABINET_ID, value: 'CABINET_ID' },
		{ label: Constants.SEARCH.CABINET_TYPE, value: 'CABINET_TYPE' },
		{ label: Constants.SEARCH.LOT, value: 'LOT_NUMBER' },
		{ label: Constants.SEARCH.PRODUCT_NAME, value: 'PRODUCT_ID' },
	],
	TransactionLogPageFilters: [
		{ label: Constants.SEARCH.CABINET_ID, value: 'CABINET_ID' },
		{ label: Constants.SEARCH.LOT, value: 'LOT_NUMBER' },
		{ label: 'Product', value: 'PRODUCT_NAME' },
		{ label: Constants.SEARCH.ACTION, value: 'ACTION' },
		{ label: Constants.SEARCH.REASON, value: 'REASON' },
		{ label: Constants.SEARCH.INVOICED, value: 'INVOICED' },
		{ label: Constants.SEARCH.SERIAL_NUMBER, value: 'SERIAL_NUMBER' },
		{ label: Constants.SEARCH.RFID_TAG, value: 'RFID_TAG' },
	],
	AlertLevels: [
		{ label: 'None', value: 'None' },
		{ label: 'Notice', value: 'Notice' },
		{ label: 'Info', value: 'Info' },
		{ label: 'Warning', value: 'Warning' },
		{ label: 'Error', value: 'Error' },
		{ label: 'Fatal', value: 'Fatal' },
	],
	PageLimitOptions: [10, 20, 30, 50, 100],
	FilterConfig: {
		DASHBOARD: {
			CUSTOMER_NAME: {}
		},
		INVOICE_REQUESTS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				PRODUCT_NAME_MULTIPLE: {
					name: 'PRODUCT_NAME_MULTIPLE',
					displayName: 'Product Name',
				},
				PRODUCT_GROUP_MULTIPLE: {
					name: 'PRODUCT_GROUP_MULTIPLE',
					displayName: 'Product Group',
				},
			},
		},
		INVOICE_REQUESTS_ADMIN: {
			CUSTOMER_NAME: {},
			DATE_RANGE: {
				displayName: 'Dispensed Date Range',
			},
			TRANSMITTAL_DATE: {},
			MORE_FILTERS: {
				CABINET_TYPE: {
					name: 'CABINET_TYPE',
					displayName: 'Device Type',
				},
				INVOICE_STATUS: {
					name: 'INVOICE_STATUS',
					displayName: 'Invoice Status',
				},
				INVOICE_ABW: {
					name: 'INVOICE_ABW',
					displayName: 'ABW Setting',
				},
				SERIAL_NUMBER: {
					name: 'TAG',
					displayName: 'Tag',
				},
				LOT_NUMBER: {
					name: 'LOT',
					displayName: 'Lot',
				},
				PRODUCT_NAME_MULTIPLE: {
					name: 'PRODUCT_NAME_MULTIPLE',
					displayName: 'Product Name',
				},
				PRODUCT_GROUP_MULTIPLE: {
					name: 'PRODUCT_GROUP_MULTIPLE',
					displayName: 'Product Group',
				},
			},
		},
		INVENTORY: {
			CUSTOMER_NAME: {},
			CABINET_TYPE: {},
			EXPIRY_DATE: {},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
				},
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				LOT_NUMBER: {
					name: 'LOT_NUMBER',
					displayName: 'Lot Number',
				},
				SERIAL_NUMBER: {
					name: 'SERIAL_NUMBER',
					displayName: 'Serial Number',
				},
				RFID_TAG: {
					name: 'RFID_TAG',
					displayName: 'RFID Tag',
				},
				PRODUCT_GROUP: {
					name: 'PRODUCT_GROUP',
					displayName: 'Product Group',
				},
				PRODUCT_OWNERSHIP: {
					name: 'PRODUCT_OWNERSHIP',
					displayName: 'Owner',
				},
			},
		},
		PAR: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				PRODUCT_GROUP: {
					name: 'PRODUCT_GROUP',
					displayName: 'Product Group',
				},
			},
		},
		INVENTORY_HISTORY: {
			CUSTOMER_NAME: {},
			HISTORY_DATE: {},
			CABINET_TYPE: { multiple: true },
		},
		TRANSACTION_LOG: {
			CUSTOMER_NAME: {},
			DATE_RANGE: {
				required: true,
			},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
					context: 'transactions',
				},
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				PRODUCT_GROUP: {
					name: 'PRODUCT_GROUP',
					displayName: 'Product Group',
				},
				LOT_NUMBER: {
					name: 'LOT_NUMBER',
					displayName: 'Lot Number',
				},
				ACTION: {
					name: 'ACTION',
					displayName: 'Action',
				},
				REASON: {
					name: 'REASON',
					displayName: 'Reason',
				},
				SERIAL_NUMBER: {
					name: 'SERIAL_NUMBER',
					displayName: 'Serial Number',
				},
				RFID_TAG: {
					name: 'RFID_TAG',
					displayName: 'RFID Tag',
				},
			},
		},
		CYCLE_COUNT: {
			CUSTOMER_NAME: {
				required: false,
			},
			DATE_RANGE: {
				required: true,
			},
		},
		DISPENSED_PRODUCTS: {
			CUSTOMER_NAME: {
				required: true,
			},
			DATE_RANGE: {
				required: true,
			},
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				LOT_NUMBER: {
					name: 'LOT_NUMBER',
					displayName: 'Lot Number',
				},
				PRODUCT_GROUP: {
					name: 'PRODUCT_GROUP',
					displayName: 'Product Group',
				},
			},
			SHOW_EXPORT_BUTTON: true,
		},
		WAREHOUSE_SHIPMENTS: {
			CUSTOMER_NAME: {
				required: true,
			},
			DATE_RANGE: {
				required: true,
			},
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				LOT_NUMBER: {
					name: 'LOT',
					displayName: 'Lot',
				},
				ORDER_NUMBER: {
					name: 'ORDER_NUMBER',
					displayName: 'Order Number',
				},
				WAREHOUSE: {
					name: 'WAREHOUSE',
					displayName: 'Warehouse',
				},
			},
			SHOW_EXPORT_BUTTON: true,
		},
		CABINET_DETAILS: {
			//aka "Cabinet List" page
			CUSTOMER_NAME: {},
			CABINET_PROPERTIES: {
				name: 'CABINET_PROPERTIES',
				displayName: 'Device Properties',
			},
			MORE_FILTERS: {
				CABINET_TYPE: {
					name: 'CABINET_TYPE',
					displayName: 'Device Type',
				},
				CABINET_STATE: {
					//aka "status"
					name: 'CABINET_STATE',
					displayName: 'Device State',
				},
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
				},
			},
		},
		DEVICE_CONFIG_MANAGEMENT: {
			CUSTOMER_NAME: {},
			CABINET_PROPERTIES: {
				name: 'CABINET_PROPERTIES',
				displayName: 'Device Properties',
			},
			MORE_FILTERS: {
				CABINET_STATE: {
					//aka "status"
					name: 'CABINET_STATE',
					displayName: 'Device State',
				},
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
				},
			},
		},
		CABINET_TEMPERATURE: {
			CUSTOMER_NAME: {
				required: true,
			},
			DATE_RANGE: {
				required: true,
			},
		},
		RESTOCK_ORDERS: {
			CUSTOMER_NAME: {},
			DATE_RANGE: {},
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
				},
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
				},
				CABINET_TYPE: {
					name: 'CABINET_TYPE',
					displayName: 'Device Type',
				},
				ORDER_TYPE: {
					name: 'ORDER_TYPE',
					displayName: 'Order Type',
				},
				ORDER_STATUS: {
					name: 'ORDER_STATUS',
					displayName: 'Order Status',
					//autoShow: true
				},
				ORDER_NUMBER: {
					name: 'ORDER_NUMBER',
					displayName: 'Order Number',
				},
			},
		},
		ORDER_REQUESTS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				ORDER_REQUEST_STATUS: {
					name: 'ORDER_REQUEST_STATUS',
					displayName: 'Status'
				},
			},
		},
		RESTOCK_ORDERS_GROUPED: {
			CUSTOMER_NAME: {},
			DATE_RANGE: {},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
				},
				CABINET_TYPE: {
					name: 'CABINET_TYPE',
					displayName: 'Device Type',
				},
				ORDER_TYPE: {
					name: 'ORDER_TYPE',
					displayName: 'Order Type',
				},
				GROUPED_ORDER_STATUS: {
					name: 'ORDER_STATUS',
					displayName: 'Order Status',
				},
				ORDER_NUMBER: {
					name: 'ORDER_NUMBER',
					displayName: 'Order Number',
				},
			},
		},
		RESTOCK_RECOMMENDATIONS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
					autoShow: true,
				},
			},
		},
		ORDER_MANAGEMENT: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				CABINET_TYPE: {
					name: 'CABINET_TYPE',
					displayName: 'Device Type',
				},
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
					autoShow: true,
				},
			},
		},
		ORDER_EXCLUSIONS: {
			CUSTOMER_NAME: {},
		},
		SHIPMENTS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				WAREHOUSE: {
					name: 'WAREHOUSE',
					displayName: 'Warehouse',
					autoShow: true,
				},
				SHIPMENT_STATUS: {
					name: 'SHIPMENT_STATUS',
					displayName: 'Shipment Status',
					autoShow: true,
				},
			},
		},
		INCIDENTS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				INCIDENT_SEVERITY: {
					name: 'INCIDENT_SEVERITY',
					displayName: 'Severity',
					autoShow: true,
				},
				INCIDENT_STATUS: {
					name: 'INCIDENT_STATUS',
					displayName: 'Status',
					autoShow: true,
				},
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
					autoShow: true,
				},
				ALERT_TYPE: {
					name: 'ALERT_TYPE',
					displayName: 'Alert Type',
					autoShow: true,
				},
			},
		},
		TELEMETRY: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
					autoShow: true,
				},
				TELEMETRY_ALERT_LEVEL: {
					name: 'TELEMETRY_ALERT_LEVEL',
					displayName: 'Alert Level',
					autoShow: true,
				},
			},
		},
		ADMIN_USERS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				USER_FIRST_NAME: {
					name: 'USER_FIRST_NAME',
					displayName: 'First Name',
				},
				USER_LAST_NAME: {
					name: 'USER_LAST_NAME',
					displayName: 'Last Name',
				},
				USER_EMAIL: {
					name: 'USER_EMAIL',
					displayName: 'Email',
				},
			},
		},
		ADMIN_CUSTOMERS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				CUSTOMER_STATE: {
					name: 'CUSTOMER_STATE',
					displayName: 'State',
				},
				CUSTOMER_ZIP_CODE: {
					name: 'CUSTOMER_ZIP_CODE',
					displayName: 'Zip Code',
				},
				MANUAL_SPLIT_BILL: {
					name: 'MANUAL_SPLIT_BILL',
					displayName: 'Manual Split Bill',
				},
				AUTO_SPLIT_BILL: {
					name: 'AUTO_SPLIT_BILL',
					displayName: 'Auto Split Bill',
				},
				CLASS_OF_TRADE: {
					name: 'CLASS_OF_TRADE',
					displayName: 'Class of Trade',
				},
				FACILITY_TYPE: {
					name: 'FACILITY_TYPE',
					displayName: 'Facility Type',
				},
			},
		},
		ADMIN_TAGS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				RFID_TAG: {
					name: 'RFID_TAG',
					displayName: 'Tag',
				},
				SERIAL_NUMBER: {
					name: 'SERIAL_NUMBER',
					displayName: 'Serial Number',
				},
				PRODUCT_ID: {
					name: 'NDC',
					displayName: 'Product NDC',
				},
				LOT_NUMBER: {
					name: 'LOT',
					displayName: 'Lot',
				},
			},
		},
		ADMIN_PRODUCTS: {
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
					autoShow: true,
				},
				// 'SALES_TYPE': {
				//   name: 'SALES_TYPE',
				//   displayName: 'Sales Type',
				//   autoShow: true
				// },
				DOSE_TYPE: {
					name: 'DOSE_TYPE',
					displayName: 'Dose Type',
					autoShow: true,
				},
			},
		},
		CUSTOMER_PRODUCTS: {
			MORE_FILTERS: {
				PRODUCT_NAME: {
					name: 'PRODUCT_NAME',
					displayName: 'Product Name',
					autoShow: true,
					customerProducts: true,
				},
			},
		},
		ADMIN_SUSPENDED_TRANSACTIONS: {
			CUSTOMER_NAME: {},
			MORE_FILTERS: {
				CABINET_ID: {
					name: 'CABINET_ID',
					displayName: 'Device ID',
					autoShow: true,
				},
				TRANSACTION_STATUS: {
					name: 'TRANSACTION_STATUS',
					displayName: 'Transaction Status',
					autoShow: true,
				},
				TRANSACTION_TYPE: {
					name: 'TRANSACTION_TYPE',
					displayName: 'Transaction Type',
					autoShow: true,
				},
			},
		},
		INVOICE: {
			CUSTOMER_NAME: {
				required: true,
			},
			DATE_RANGE: {
				required: true
			},
			MORE_FILTERS: {
				INVOICE_NUMBER: {
					name: 'INVOICE_NUMBER',
					displayName: 'Invoice Number'
				}
			},
		},
		AUDITLOG: {
			CUSTOMER_NAME: {},
			DATE_RANGE: {},
			MORE_FILTERS: {
				RESOURCE_TYPE: {
					name: 'RESOURCE_TYPE',
					displayName: 'Resource Type',
					autoShow: true,
				},
				INITIATOR_TYPE: {
					name: 'INITIATOR_TYPE',
					displayName: 'Initiator Type',
					autoShow: true,
				},
				EVENT_TYPE: {
					name: 'EVENT_TYPE',
					displayName: 'Event Type',
					autoShow: true,
				},
			},
		},
		SAVE_SEARCH: {
			TITLE: {},
		},
	},

	DefaultFilterVals: {
		CABINET_ID: '',
		PRODUCT_ID: '',
		LOT_NUMBER: '',
		ACTION: '',
		REASON: '',
		INVOICED: '',
		SERIAL_NUMBER: '',
		RFID_TAG: '',
		PRODUCT_NAME: '',
		CABINET_TYPE: '',
		CABINET_STATE: '',
		ORDER_STATUS: '',
		SHIPMENT_STATUS: '',
		ORDER_REQUEST_STATUS: 'PENDING',
		WAREHOUSE: '',
		INCIDENT_STATUS: '',
		INCIDENT_SEVERITY: '',
		TELEMETRY_ALERT_LEVEL: '',
		USER_FIRST_NAME: '',
		USER_LAST_NAME: '',
		USER_EMAIL: '',
		CUSTOMER_SETUP_STATUS: '',
		CUSTOMER_STATE: '',
		CUSTOMER_ZIP_CODE: '',
		CUSTOMER_CREATED_DATE: '',
		SALES_TYPE: '',
		DOSE_TYPE: '',
		TRANSACTION_STATUS: '',
		ORDER_TYPE: '',
		TRANSACTION_TYPE: '',
	},

	DateFormats: [
		{ label: 'YYYY-MM-DD', value: 'yyyy-MM-DD' },
		{ label: 'YY-MM-DD', value: 'YY-MM-DD' },
		{ label: 'MM-DD-YYYY', value: 'MM-DD-yyyy' },
		{ label: 'MM-DD-YY', value: 'MM-DD-YY' },
		{ label: 'DD-MM-YYYY', value: 'DD-MM-yyyy' },
		{ label: 'DD-MM-YY', value: 'DD-MM-YY' },
	],

	PickerDateFormats: [
		{ label: 'YYYY-MM-DD', value: 'yyyy-MM-dd' },
		{ label: 'YY-MM-DD', value: 'yy-MM-dd' },
		{ label: 'MM-DD-YYYY', value: 'MM-dd-yyyy' },
		{ label: 'MM-DD-YY', value: 'MM-dd-yy' },
		{ label: 'DD-MM-YYYY', value: 'dd-MM-yyyy' },
		{ label: 'DD-MM-YY', value: 'dd-MM-yy' },
	],

	TimeFormats: [
		{ label: '24 Hour', value: 'HH:mm:ss zz' },
		{ label: '12 Hour', value: 'hh:mm:ssA zz' },
	],

	IgnoreDurations: [
		{ label: '15 Minutes', value: '15' },
		{ label: '30 Minutes', value: '30' },
		{ label: '60 Minutes', value: '60' },
		{ label: '120 Minutes', value: '120' },
	],

	ConnectivityStatus: [
		{ label: 'OFFLINE (<15min)', value: '2' },
		{ label: 'OFFLINE', value: '3' },
		{ label: 'ONLINE', value: '4' },
	],

	TransActionStatuses: [
		{ label: 'Approved', value: 'Approved' },
		{ label: 'Rejected', value: 'Rejected' },
		{ label: 'Suspended', value: 'Suspended' },
	],

	SalesTypes: [
		{ label: 'FBB', value: 'FBB' },
		{ label: 'FCP', value: 'FCP' },
	],

	ShipmentStatuses: [
		{ label: 'Success', value: 'Success' },
		{ label: 'Complete', value: 'Complete' },
		{ label: 'Virtual', value: 'Virtual' },
		{ label: 'Duplicate', value: 'Duplicate' },
		{ label: 'Pending', value: 'Pending' },
		{ label: 'Error', value: 'Error' },
		{ label: 'N/A', value: 'NA' },
	],

	orderRequestStatus: [
		{ label: 'Pending', value: 'PENDING' },
		{ label: 'Approved', value: 'APPROVED' },
		{ label: 'Denied', value: 'DENIED' },
		{ label: 'Cancelled', value: 'CANCELLED' },
	],

	restockRecommendationStatuses: [
		{ label: 'Urgent', value: 'Urgent' },
		{ label: 'Replen', value: 'Replen' },
		{ label: 'Opportunity', value: 'Opportunity' },
		{ label: 'Stocked', value: 'Stocked' },
		{ label: 'Failed', value: 'Failed' },
		{ label: 'Not Processed', value: 'Not Processed' },
		{ label: 'Gap', value: 'Gap' },
		{ label: 'Order Outstanding', value: 'Order Outstanding' },
	],

	dow: [
		{ label: 'Monday', value: 'Monday' },
		{ label: 'Tuesday', value: 'Tuesday' },
		{ label: 'Wednesday', value: 'Wednesday' },
		{ label: 'Thursday', value: 'Thursday' },
		{ label: 'Friday', value: 'Friday' },
		{ label: 'Saturday', value: 'Saturday' },
		{ label: 'Sunday', value: 'Sunday' },
	],

	Warehouses: [
		{ label: 'NCDC', value: 'NCDC' },
		{ label: 'SCDC', value: 'SCDC' },
		{ label: 'TXDC', value: 'TXDC' },
	],

	DefaultInventories: [
		{ label: 'Lot Inventory', value: 'LOT' },
		{ label: 'Cartridge Inventory', value: 'CARTRIDGE' },
		{ label: 'Serial Inventory', value: 'SERIAL' },
	],

	ResourceTypes: [
		{ label: 'CABINET', value: 'CABINET' },
		{ label: 'CABINET PRODUCT', value: 'CABINET PRODUCT' },
		{ label: 'CABINET PROVIDER', value: 'CABINET PROVIDER' },
		{ label: 'CABINET USER', value: 'CABINET USER' },
		{ label: 'CHANNEL GROUP', value: 'CHANNEL GROUP' },
		{ label: 'CUSTOMER ACCOUNT', value: 'CUSTOMER ACCOUNT' },
		{ label: 'CUSTOMER PAR SETTING', value: 'CUSTOMER PAR SETTING' },
		{ label: 'EMAIL', value: 'EMAIL' },
		{ label: 'ORDER', value: 'ORDER' },
		{ label: 'PAR RECORD', value: 'PAR RECORD' },
		{ label: 'PRODUCT', value: 'PRODUCT' },
		{ label: 'PERSONA', value: 'PERSONA' },
		{ label: 'PRODUCT', value: 'PRODUCT' },
		{ label: 'RFID TAG', value: 'RFID TAG' },
		{ label: 'SAP SHIP FILE', value: 'SAP SHIP FILE' },
		{ label: 'SCAN ID', value: 'SCAN ID' },
		{ label: 'USER', value: 'USER' },
		{ label: 'INVOICE', value: 'INVOICE' },
	],

	InitiatedByList: [
		{ label: 'MACHINE', value: 'MACHINE' },
		{ label: 'USER', value: 'USER' },
	],

	TransactionTypes: [
		{ label: 'Cycle Count', value: 'CYCLE COUNT' },
		{ label: 'Insert', value: 'INSERT' },
		{ label: 'Removal', value: 'REMOVAL' },
	],
	ScheduledReportTemplates: [
		{ label: 'Dashboard Export', value: 'TrackDashboardExport'},
		{ label: 'Dispensed Report', value: 'DispensedReport' },
		//{ "label": "Provider Report", "value": "ProviderReport" },
		//{ "label": "Shipment Report", "value": "ShipmentReport" },
		{ label: 'Temperature Extract Report', value: 'TemperatureExtractReport' },
		{ label: 'Current Inventory Report', value: 'CurrentInventoryReport' },
		{ label: 'Restock Recommendations Report', value: 'RestockRecommendationsReport' },
	],
	AdminScheduledReportTemplates: [
		{ label: 'In Transit Report', value: 'InTransitReport' },
		{ label: 'Last Cycle Count Report', value: 'RNI_UIEXPORT_LastCycleCountReport' },
		{ label: 'Suspect Tags Report', value: 'RNI_UIEXPORT_SuspectTagsReport' },
		{ label: 'Cabinet Connectivity Report', value: 'CabinetConnectivityReport' },
		{ label: 'Cabinet Inventory Sync Status Report', value: 'CabinetInventorySyncStatusReport' },
		{ label: 'Temperature Excursion Report', value: 'TemperatureExcursionReport' },
		{ label: 'ABW Selection Report', value: 'ABWSelectionReport' },
		{ label: 'PAR & Usage Report', value: 'ParUsageReport' },
	],
	ScheduledReportFormats: [
		{ label: 'csv', value: 'csv' },
		{ label: 'pdf', value: 'pdf' },
		{ label: 'xlsx', value: 'xlsx' }
	],
	ScheduledReportDeliveryMethods: [
		{ label: 'email download link', value: 'Email' },
		{ "label": "email attachment", "value": "EmailAttachment"}
		//{ "label": "SMS", "value": "SMS" }
	],
	ScheduledReportDeliveryFrequencies: [
		{ label: 'Daily', value: 'Daily' },
		{ label: 'Weekly', value: 'Weekly' },
		{ label: 'Monthly', value: 'Monthly' },
	],
	DayOfWeekChoices: [
		{ label: 'Monday', value: 1 },
		{ label: 'Tuesday', value: 2 },
		{ label: 'Wednesday', value: 3 },
		{ label: 'Thursday', value: 4 },
		{ label: 'Friday', value: 5 },
		{ label: 'Saturday', value: 6 },
		{ label: 'Sunday', value: 7 },
	],
	DayOfWeekMap: {
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
		7: 'Sunday',
	},
	ManualSplitBill: [
		{ label: 'Yes', value: 'Y' },
		{ label: 'No', value: 'N' },
	],
	cabinetStates: ['10', '20', '21', '22', '30', '31', '40', '50', '60', '70'],
	InvoiceStates: [
		{ label: 'Ready to Send', value: 'READY' },
		{ label: 'Pending ABW', value: 'PENDING ABW' },
		{ label: 'Omitted', value: 'OMITTED' },
		{ label: 'Sent to SAP', value: 'TRANSMITTED' },
		{ label: 'On Hold', value: 'ON HOLD' },
	],
	AbwOptions: [
		{ label: 'A', value: 'A' },
		{ label: 'B', value: 'B' },
		{ label: 'W', value: 'W' },
	],
	ProductOwnershipOptions: [
		{ label: 'FFF Enterprises', value: 'FFF Enterprises' },
		{ label: 'Customer', value: 'Customer' },
	],
};
