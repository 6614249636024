import React from "react";
import { DashboardStyles, getModalClasses, getModalStyle } from "../../../hooks/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from 'highcharts';
import { valueMapper } from "../../../hooks/functions";

const cabinetTypes = ['EM', 'RFID', 'Virtual', 'WEIGHT', 'VISION', 'SITE', 'ZONE']

const DashboardPieChartRendering: React.FC<any> = props => {

    console.log(`DashboardPieChartRendering called`)
    const modalClasses = getModalClasses();
    const [modalStyling] = React.useState(getModalStyle());
    const dashboardStyling = DashboardStyles();
    const isModal = props.settings?.modalPopUp || false;

    const styleToUse = isModal ? modalStyling : dashboardStyling;

    const handleClose = () => {
        props.closeChart();
    };

    const options = {
        title: {
            text: props.settings.displayName
        },
        chart: {
            type: "pie"
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                animation: props?.animation ?? true,
                series: {
                    dataLabels: {
                        enabled: true
                    },
                },
                showInLegend: true
            }
        },

        series: [{
            name: 'Records',
            colorByPoint: true,
            data: props.records
                .map((el: any) => {
                    return {
                        name: cabinetTypes.includes(el.name as string) ? valueMapper(el.name, 'cabinetTypes') : el.name,
                        y: el.y
                    }
                })
        }]
    };

    return (

        <div style={{ ...styleToUse, maxWidth: 1200 }} className={isModal === true ? modalClasses.paper : dashboardStyling.cardItem}>
            {isModal === true &&
                <div className={modalClasses.closeBtn} onClick={handleClose}>
                <CancelIcon color="disabled"></CancelIcon>
            </div>}
            <div>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPieChartRendering);